import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import { Icon } from 'leaflet';
import markerIconPng from "leaflet/dist/images/marker-icon.png"
import 'leaflet/dist/leaflet.css';
//import { FaCaravan } from 'react-icons/fa';
//import { GiCampingTent} from 'react-icons/gi';
import mh from '../assets/pictures/mobile-home.png';
import caravan from '../assets/pictures/caravan3.png';
import tent from '../assets/pictures/camping.png';
import staticc from '../assets/pictures/caravan2.png';
import cv from '../assets/pictures/vwcamper.png';
// --- ---------------------------------- ---

// <FaCaravan className="text-lg mr-1" />
//<GiCampingTent className="text-lg mr-1" />
//
export function Map() {
  // Site coordinates
  const position = [54.8590, -3.7870]

  return (
    <section className='map-component' >
      {/* --- (5) Add leaflet map container --- */}
      <div className='map'>
      <MapContainer center={position} zoom={12} scrollWheelZoom={true}>
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          // --- (7) Alternative map style (attribution and url copied from the leaflet extras website) ---
          // attribution='&copy; <a href="https://stadiamaps.com/">Stadia Maps</a>, &copy; <a href="https://openmaptiles.org/">OpenMapTiles</a> &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors'
          // url='https://tiles.stadiamaps.com/tiles/alidade_smooth_dark/{z}/{x}/{y}{r}.png'
          // --- -------------------------------------------------------------------------------------- ---
        />
        <Marker position={position} icon={new Icon({iconUrl: markerIconPng, iconSize: [25, 41], iconAnchor: [12, 41]})}
        
        >
          <Popup>
<div className="flex-column">
           <img  src={mh}  width="20" height="20"  alt="motorhome"/>
           <img  src={caravan}  width="20" height="20"  alt="caravan"/>
           <img  src={tent}  width="20" height="20"  alt="tent"/>
           <img  src={staticc}  width="20" height="20"  alt="static"/>
           <img  src={cv}  width="20" height="20"  alt="cv"/>  </div>
          </Popup>
        </Marker>
      </MapContainer>
      {/* --- ---------------------------- --- */}
      </div>
    </section>
  )
}