import React from 'react'

export default function Booking() {
  return (
    <form >
    
    <div className="flex ">
        <div className="  bg-zinc-100 p-5" >
            <h2>IsBooked</h2>
            
        </div>
        <div className="  bg-zinc-100 p-5">
             <h2>Price</h2>
           
        </div>
        <div className="  bg-zinc-100 p-5">
            <h2>Arrive Date</h2>
            
        </div>
        <div className="  bg-zinc-100 p-5">
            <h2>Leave  Date</h2>
           
        </div>
    </div>
</form>
  )
}
