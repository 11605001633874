import React from 'react'

import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, {
  EffectFade,
  Autoplay,
  Navigation,
  Pagination,
} from "swiper";
import "swiper/css/bundle";
import p1 from '../assets/pictures/CliffsOverPoint.JPG'; 
import p2 from '../assets/pictures/-5855173269773205093_121.jpg'; 
import p3 from '../assets/pictures/FromPointField2.jpg'; 
import p4 from '../assets/pictures/glenstocken.jpeg'; 





//import { Helmet } from 'react-helmet';

export default function Tourers() {

  SwiperCore.use([Autoplay, Navigation, Pagination]);

  const arrayOfImages=[p1,p2,p3,p4]

  return (
    <>
    
    <Swiper
            slidesPerView={1}
            navigation
            pagination={{ type: "progressbar" }}
            effect="fade"
            modules={[EffectFade]}
            autoplay={{ delay: 3000 }}
          >
            {arrayOfImages.map((url, index) => (
              <SwiperSlide key={index}>
                <div 
                
                className=" relative w-full overflow-hidden h-[300px] " 
                  style={{
                    background: `url(${arrayOfImages[index]})  center no-repeat`,
                    backgroundSize: "cover ",
                  }}
                ></div>
              </SwiperSlide>
            ))}
          </Swiper>







    <div  class="flex flex-col md:flex-row md:max-w-x3 rounded-lg bg-white shadow-lg">

        <div class="p-6 flex flex-col justify-start">
          <h5 class="font-black text-xl font-medium mb-2">Check Availability and book a Touring Caravan, Motor Home or Camping Pitch  - Prices 2024 and 2025   </h5>
          <p class="text-gray-900 text-base mb-4">
            Nightly Charges (including electricity, showers and WiFi access)
          </p>
          <p class="font-black text-base mb-4">
              High Season ( 12th April - 21st April, 24th May - 14th September inclusive)
          </p>
          <p class="text-gray-700 text-base mb-4">
            £24, £32, £32, £38 per pitch and two people for small, medium, large, extra large tents respectively</p>
            <p class="text-gray-700 text-base mb-4">
            £33 for a motor home, camper van or touring caravan and two people</p>
            <p class="text-gray-700 text-base mb-4">
              £4.25 for an awning and £3.50 for a porch
              £3.75 for each additional person (£2.75 ages 5-16)
          </p>
          <p class="font-black text-base mb-4">
            Low Season (all other dates)
          </p>
          <p class="text-gray-700 text-base mb-4">
          £20, £28, £28, £34 per pitch and two people for small, medium, large, extra large tents respectively</p>
          <p class="text-gray-700 text-base mb-4">
           £29 for a motor home, camper van or touring caravan and two people</p>
           <p class="text-gray-700 text-base mb-4">
            £3.50 for an awning and £2.75 for a porch
            £3.75 for each additional person (£2.75 ages 5-16)
          </p>
            <p></p>
              <p class="text-gray-700 text-base mb-4">
              Gazebos and pup tents £10 per night
              </p>
              <p class="text-gray-700 text-base mb-4">
              Children under 5 are free
              </p>
              <p class="text-gray-700 text-base mb-4">
              Dogs are £1.50 per night
              </p>
              <p class="text-gray-700 text-base mb-4">
            Extra cars are £2.00 per night

          </p>
    <p class="text-green-700 text-base mb-8">
    Select an arrival date and a leaving date or use the calendar to pick a start date. 
    The term "Restrictions apply" on the calendar refers only to some of the hire caravans. Whilst most statics can be rented for a minimum of three nights the A+ caravans can only be booked from Saturday to Saturday,
    unless by special arrangement. Hence these hire caravans will not appear as a booking option unless a Saturday to Saturday date is entered.
      </p>
      <p class="text-gray-700 text-xs display: inline">
    The initial deposit is not refundable.  Cancellations to bookings more than 14 days  prior to arrival are without further charge. <b> Full payment is due 14 days prior to arrival.</b> Failure to make balance payments on time means your accommodation will be offered for re-letting.  All cancellations prior to 14 days must be confirmed by email to be registered.

Changes to bookings more than 14 days prior to arrival are without further charge except where 
the revised booking costs is more than the previous booking, when the balance of the increased 
deposit should be paid. The deposit is 15%
      </p>
    </div>




    
 

   
    
    

    </div>
</>
  )
}



{/* 
<div id="qbook_widget" class="p-6 flex flex-col justify-start">
<Helmet>

  <script src="https://secure.hotels.uk.com/js/widgets.js" data-qbook="49345" data-token="383c25466be708e0ee9b23cabe4304f3"></script>
  </Helmet>
  </div>
this works */ }