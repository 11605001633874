import React from 'react'
//import { Helmet } from 'react-helmet';

import amethystplan from '../assets/pictures/amethystplan.jpg';
import bentudor from '../assets/pictures/Bentudor.jpg';
import screel from '../assets/pictures/screel.jpg';

import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, {
  EffectFade,
  Autoplay,
  Navigation,
  Pagination,
} from "swiper";
import "swiper/css/bundle";




export default function CaravansForSale() {

  SwiperCore.use([Autoplay, Navigation, Pagination]);

  
  const arrayOfCaravans=[bentudor,screel]
  return (
    <>

<Swiper
            slidesPerView={1}
            navigation
            pagination={{ type: "progressbar" }}
            effect="fade"
            modules={[EffectFade]}
            autoplay={{ delay: 3000 }}
          >
            {arrayOfCaravans.map((url, index) => (
              <SwiperSlide key={index}>
                <div 
                
                className=" relative w-full overflow-hidden h-[300px] " 
                  style={{
                    background: `url(${arrayOfCaravans[index]})  center no-repeat`,
                    backgroundSize: "cover ",
                  }}
                ></div>
              </SwiperSlide>
            ))}
          </Swiper>
    <div  class="flex flex-col md:flex-row md:max-w-x3 rounded-lg bg-white shadow-lg">

        <div class="p-6 flex flex-col justify-start">
          <h5 class="text-gray-900 text-xl font-medium mb-2">A limited number of new and second hand holiday homes are available to buy. All have excellent views over the sea. Views from balconies as above. </h5>
        
          <p class="text-gray-700 text-base mb-4">
          Two Atlas Amethyst , 28ft x 12ft, 2 Bedrooms ,  installed in 2019. Sold with 15 summer agreement. (Serial Numbers  17476, 18190)
Each Atlas Amethyst is double glazed, extra insulation, CH and French Doors. There are two bedrooms and the living area seats can convert to a double bed. Each has a large balcony and unobstructed views of the sea. They were was installed on site in 2019. Plan at end of this document.
Large sized balcony overlooking the sea. 
          </p>
          <p class="text-gray-700 text-base mb-4">

Pitch fees are £3800 p.a. inclusive of VAT, water and council tax
Pictures and full details available on request johnbighamgm@gmail.com. 
          </p>
        <div
          className=" relative w-full overflow-hidden h-[300px] " 
                  style={{
                    background: `url(${amethystplan})  center no-repeat`,
                    backgroundSize: "contain ",
                  }}
                ></div>


        </div>



    </div>
</>



)


}
