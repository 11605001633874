import { useState } from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { FaCaravan } from 'react-icons/fa';
import { FaMobileAlt } from 'react-icons/fa';
import { GiCampingTent } from 'react-icons/gi';
import { GrLocation } from 'react-icons/gr';
import { BsMailbox } from 'react-icons/bs';

import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, {
  EffectFade,
  Autoplay,
  Navigation,
  Pagination,
} from "swiper";
import "swiper/css/bundle";
import p1 from '../assets/pictures/84204.webp'; 
import p2 from '../assets/pictures/84206.webp'; 
import p3 from '../assets/pictures/tentback.jpg'; 
import p4 from '../assets/pictures/159506.webp'; 
import p5 from '../assets/pictures/fromtentarea.jpg'; 
import p6 from '../assets/pictures/rockcliffefromsea.jpg';
import p7 from '../assets/pictures/lowerarea.jpg';


import p8 from '../assets/pictures/beach.webp';

import {Map} from '../components/Map';

export default function Home() {
    SwiperCore.use([Autoplay, Navigation, Pagination]);

   const arrayOfImages=[p5,p7, p1,p2,p3,p4,p6,p8]
  
   
   
    return (
        <main>
          <Swiper
            slidesPerView={1}
            navigation
            pagination={{ type: "progressbar" }}
            effect="fade"
            modules={[EffectFade]}
            autoplay={{ delay: 3000 }}
          >
            {arrayOfImages.map((url, index) => (
              <SwiperSlide key={index}>
                <div 
                
                className=" relative w-full overflow-hidden h-[300px] " 
                  style={{
                    background: `url(${arrayOfImages[index]})  center no-repeat`,
                    backgroundSize: "cover ",
                  }}
                ></div>
              </SwiperSlide>
            ))}
          </Swiper>
          

      <div class="flex  justify-center px-4 py-4 ">
  <div class="flex flex-col md:flex-row md:max-w-x3 rounded-lg bg-white shadow-lg">

    <div class="p-6 flex flex-col justify-start">
      <h5 class="text-gray-900 text-xl font-medium mb-2">Castle Point Caravan and Camping Site</h5>
      <p class="text-gray-700 text-base mb-4">
      Warden: Charles Hamilton
      </p> 
      
      <p class="text-gray-700 text-base mb-4">
      Touring Caravans, Motor Homes, Tents and Static Caravans
      </p>
      <p class="text-gray-700 text-base mb-4">
      Open 20th March to 28th October 2024 inclusive;  Open 20th March to 30th October 2025 inclusive
      </p>
      <p class="text-gray-700 text-base mb-4">
      Holiday Static Caravans for Hire and Sale
      </p>
      <p class="text-gray-700 text-base mb-4">
      Online availability and booking now possible
      </p>

      <p class="text-gray-700 text-base mb-4">
      We are a small site 300 yards from the sea and within short walking distance to three sandy beaches. 
      The site and its surrounds have some of the best views in south west Scotland. 
      
      </p>
       
    </div>

    <Map />


    <div class="flex justify-center px-6 py-12 ">
    <ul class="bg-white rounded-lg border border-gray-200 w-96 text-gray-900">

    <li class="flex items-center px-6 py-2 border-b border-gray-200 w-full space-x-2 "> 
   
    <GrLocation  className="text-lg mr-1"  />  <p>Rockcliffe, Galloway, DG5 4QL </p></li>

    <li class="flex items-center px-6 py-2 border-b border-gray-200 w-full space-x-2 ">  
    <BsMailbox /> <p>castlepointcs@gmail.com </p>  </li>
      
    <li class="flex-col items-center px-6 py-2 border-b border-gray-200 w-full ">
     <div className="flex  items-center space-x-2"><FaMobileAlt   size='1.0rem'/> <p>07733467879  ideally ring between 8:00 a.m. and 5:00 p.m. as then reception is open</p></div>
     
     <div className="flex  items-center space-x-2"><FaMobileAlt   size='1.0rem' /> <p>07554580259 if no reply from above  </p></div></li>

     <div className="flex  items-center space-x-2"><p>From December through January please use email or online booking or just phone phone   07554580259 </p></div>



    </ul>
    </div>




    
  </div>
</div>
        


  <div  class="flex flex-col md:flex-row md:max-w-x3 rounded-lg bg-white shadow-lg">

    <div class="p-6 flex flex-col justify-start">
      <h5 class="text-gray-900 text-xl font-medium mb-2">Castle Point Caravan and Camping Site</h5>
      <p class="text-gray-700 text-base mb-4">
      Consistently rated 4 star by the Scottish Tourist board
      </p>
      <p class="text-gray-700 text-base mb-4">
      Electricity, access to WiFi hotspot, hot water and showers included in the price
      </p>
      <p class="text-gray-700 text-base mb-4">
      Pets allowed (but not in selected hire caravans)
      </p>
      <p class="text-gray-700 text-base mb-4">
      Laundry, drier and wash up areas, 2 toilet blocks and disabled toilet and shower rooms
      </p>
      <p class="text-gray-700 text-base mb-4">
      Lock up cycle shed and bike wash up
      </p>
      <p class="text-gray-700 text-base mb-4">
      Play area with swings, agility frame, football area and table tennis
      </p>
      <p class="text-gray-700 text-base mb-4">
      Drying cabinet for wet clothes and foot wear
      </p>
      <p class="text-gray-700 text-base mb-4">
      Electric vehicle charging points
      </p>

      <p class="text-gray-700 text-base mb-4">
    Caravan storage possible for touring customers 
      </p>
       
      <p class="text-blue-700 text-base mb-8">
    For more information regarding tourers, tents, camper vans, motor homes or hire caravans 
    bookings please choose the appropriate tab above.
      </p>
      <p class="text-gray-700 text-base mb-4">Some of the hire caravans can only be booked Saturday to Saturday</p>
      <p class="text-green-700 text-base mb-8">
      Select an arrival date and a leaving date or use the calendar to pick a start date. 
    The term "Restrictions apply" on the calendar refers only to some of the hire caravans. Whilst most statics can be rented for a minimum of three nights the A+ caravans can only be booked from Saturday to Saturday,
    unless by special arrangement. Hence these hire caravans will not appear as a booking option unless a Saturday to Saturday date is entered.
    </p>
 
    </div>
    
    </div>
    
{/* 
    <Swiper
            slidesPerView={1}
            navigation
            pagination={{ type: "progressbar" }}
            effect="fade"
            modules={[EffectFade]}
            autoplay={{ delay: 3000 }}
          >
            {arrayOfImages.map((url, index) => (
              <SwiperSlide key={index}>
                <div 
                
                className=" relative w-full overflow-hidden h-[300px] " 
                  style={{
                    background: `url(${arrayOfImages[index]})  center no-repeat`,
                    backgroundSize: "cover ",
                  }}
                ></div>
              </SwiperSlide>
            ))}
          </Swiper>

*/ }



        
        </main>
  )
}

