// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {getFirestore} from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration

const firebaseConfig = {

  apiKey: "AIzaSyAqLbFg92sljySl_25QsllX0m_wE8KHjS0",

  authDomain: "caravan-site-warden.firebaseapp.com",

  projectId: "caravan-site-warden",

  storageBucket: "caravan-site-warden.appspot.com",

  messagingSenderId: "634943395724",

  appId: "1:634943395724:web:61acb76d87101d4419e8a9"

};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db= getFirestore();