import React from 'react'
//import { Helmet } from 'react-helmet';

import anderson from '../assets/pictures/anderson.jpg'; 
import jenny from '../assets/pictures/jenny.jpg'; 
import motorhome from '../assets/pictures/motorhome.jpg'; 
import play from '../assets/pictures/play.jpg'; 

import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, {
  EffectFade,
  Autoplay,
  Navigation,
  Pagination,
} from "swiper";
import "swiper/css/bundle";




export default function LocalActivities() {

  SwiperCore.use([Autoplay, Navigation, Pagination]);

  
  const arrayOfImages=[anderson,jenny,motorhome, play]
  return (
    <>

<Swiper
            slidesPerView={1}
            navigation
            pagination={{ type: "progressbar" }}
            effect="fade"
            modules={[EffectFade]}
            autoplay={{ delay: 3000 }}
          >
            {arrayOfImages.map((url, index) => (
              <SwiperSlide key={index}>
                <div 
                
                className=" relative w-full overflow-hidden h-[300px] " 
                  style={{
                    background: `url(${arrayOfImages[index]})  center no-repeat`,
                    backgroundSize: "cover ",
                  }}
                ></div>
              </SwiperSlide>
            ))}
          </Swiper>

    <div  class="flex flex-col md:flex-row md:max-w-x3 rounded-lg bg-white shadow-lg">

        

          <div  class="flex flex-col md:flex-row md:max-w-x3 rounded-lg bg-white shadow-lg">

            <div class="p-6 flex flex-col justify-start">
              <h5 class="text-gray-900 text-xl font-medium mb-2">Local Activities,Tide & Weather</h5>
              <p class="text-gray-700 text-base mb-4">
              Castle Point itself is the site of an ancient Roman fort on a headland at the estuary of the river Urr. The headland provides panoramic views across to the Lake District, the Isle of Man, Heston Island, Rough Island and up the estuary to Kippford. There are lovely walks from the site - over the cliffs from Castle Point past Glenstocken cove, the Monument (lots of cormorants at nesting time ) and Sandyhills. Other walks are along the Merse to Rockcliffe, along the Jubilee Path to the sailing village of Kippford (coffee or beer at the pub at the harbour), and up the Muckle Knowe and Mote of Mark (a 5th Century fort). When the tide is out it is possible to walk from the site across to the bird sanctuary of Rough Island. 
              Information on walks around the site and in Galloway can be found at <a className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600" href="https://www.walkhighlands.co.uk/galloway/">Walks in Galloway</a>. This site also provides maps.
               
              <a className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600" href="https://www.nts.org.uk/visit/places/rockcliffe/things-to-do"> Rockcliffe </a>is a pretty village a short walk away with an ideal beach for children. 
              The times of the tides and weather can be found at 
              <a className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600" href="https://tides.today/en/%F0%9F%8C%8D/scotland/kirkcudbrightshire/hestan-island"> Tide Timetable</a>

               
               
               </p>
              <p class="text-gray-700 text-base mb-4">
              The site is very close to the  <a className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600" href="https://forestryandland.gov.scot/visit/activities/mountain-biking/7stanes">7 Stanes</a> for the nearby purpose built mountain bike trail and cycle tracks in the forestry see 
              the <a className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600" href="https://www.youtube.com/watch?v=nxhINLCiCJI">YouTube video</a>.
              We offer a locking cycle shed for bikes and a drying room if it is wet.
              </p>
              <p class="text-gray-700 text-base mb-4">
              Watersports, archery, laser quest and other activities can be found at the <a className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600" href="https://www.lochken.co.uk/">Galloway Activity Centre </a> .
              Children will also enjoy the animals and activities at <a className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600" href="https://mabiefarmpark.co.uk/">Mabie Farm Park</a>. 
              </p>
              
              <p class="text-gray-700 text-base mb-4">
                It is a short drive or pleasant coastal walk to the popular sailing centre of Kippford with its pubs and restaurants.
              Details of the yacht club are at <a className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600" href="https://www.thesyc.co.uk/">Solway Yacht Club </a> 
              </p>
             
              <p class="text-gray-700 text-base mb-4">
              Details about the Galloway red kite trail can be found at 
              <a className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600" href="https://www.visitscotland.com/info/see-do/galloway-kite-trail-p582081"> Red Kite Trail.</a>
               Also there is the  
              <a className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600" href="  https://www.creamogalloway.co.uk/">  Cream O'Galloway</a>
                ice cream factory, which has pre-school activities; 
              a  <a className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600" href="https://www.thecocoabeancompany.com/"> Cocoa Bean Factory </a> 
               (with indoor and outdoor activities); 
              and a bird sanctuary at  
              <a className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600" href="https://www.rspb.org.uk/"> RSPB Mersehead</a> (with activities for adults and children) nearby.
              </p>
              <p class="text-gray-700 text-base mb-4">
            
            Several 9 and 18 hole golf courses, with non member access, are nearby. The closest 18 hole is at 
            <a className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600" href="https://www.colvendgolfclub.co.uk/"> Colvend Golf Club</a>.
              </p>
              <p class="text-gray-700 text-base mb-4">
              For hill walkers Screel, Bengairn, Cairnsmore of Fleet, and Criffel are close; in the 
              <a className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600" href="https://forestryandland.gov.scot/"> Galloway National Park </a>.(three hundred square miles) Merrick and Mulwharcher are within easy travelling distance. 

              This area is also famous for its dark sky an attraction for star gazers. The sky around the site is also dark.
              </p>
              <p class="text-gray-700 text-base mb-4">
              For fishing see e.g.  
              <a className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600" href="https://www.fishpal.com/"> Fishing in Galloway</a> .
            
              </p>

            </div>

            </div>






    </div>

</>


)


}
